import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

const StyledWrapper = styled(Box)`
  padding: 0.5rem;
  ${props => props.theme.breakpoints.up("md")} {
    padding: 0.5rem 0;
  }
`

const StyledLetterWrapper = styled(Box)`
  margin-bottom: 2rem;
  ${props => props.theme.breakpoints.up("md")} {
    margin-bottom: 3rem;
  }
`

const StyledButtonCity = styled(Button)`
  text-transform: inherit;
`
const StyledButtonLetter = styled(Button)`
  ${props => props.theme.typography.h3};
  font-weight: 700;
  min-width: 2.25em;
  border-radius: 2.5em;
  color: ${props => props.theme.palette.primary.light};
  &.active-letter,
  &:hover {
    color: ${props => props.theme.palette.primary.main};
    background: ${props => props.theme.palette.secondary.contrastText};
  }
`

export default function Cities(props) {
  const { current, cities } = props
  const letters = [..."abcdefghijklmnopqrstuvwxyz"].filter(
    letter =>
      cities?.filter(entry => entry.city.charAt(0).toLowerCase() === letter)
        .length > 0
  )

  const [selectedLetter, setSelectedLetter] = React.useState(letters[0])

  return (
    <StyledWrapper>
      <StyledLetterWrapper>
        {letters.map(letter => (
          <StyledButtonLetter
            className={letter === selectedLetter && "active-letter"}
            onClick={() => setSelectedLetter(letter)}
            key={letter}
          >
            {letter}
          </StyledButtonLetter>
        ))}
      </StyledLetterWrapper>
      {letters.map(letter => (
        <Box
          key={letter}
          display={letter === selectedLetter ? "block" : "none"}
        >
          <Grid container>
            {cities
              .filter(entry => entry.city.charAt(0).toLowerCase() === letter)
              .map(city => (
                <Grid item xs={12} sm={6} md={3} key={city.placecleaned}>
                  <StyledButtonCity
                    href={`/${city.countycleaned}/${city.placecleaned}`}
                    title={`Tankstellen in ${city.city}`}
                  >
                    {city.city} ({city.count})
                  </StyledButtonCity>
                </Grid>
              ))}
          </Grid>
        </Box>
      ))}
    </StyledWrapper>
  )
}
