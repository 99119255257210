import React, { useState, useEffect, useRef } from "react"
import GoogleMapReact from "google-map-react"
import MarkerClusterer from "@google/markerclusterer"
import useGeolocation from "react-hook-geolocation"
import useFetch from "use-http"

import styles from "../../maps-config"
import env from "../../env-config.json"

import markerIconInactive from "../../static/marker/marker-inactive.png"
import markerIconActive from "../../static/marker/marker-active.png"
import markerIconSelfActive from "../../static/marker/self-active.png"

function LocationMap(props) {
  const { featured, leading, type } = props

  const geolocation = useGeolocation()

  let defaultCenter = env.maps.defaultCenter
  let defaultZoom = env.maps.defaultZoom.country

  if (type !== "city" && type !== "county" && featured) {
    defaultCenter = {
      lat: featured?.coords?.lat,
      lng: featured?.coords?.lng,
    }
    defaultZoom = env.maps.defaultZoom.station
  }

  if (type === "city" || (type === "county" && leading)) {
    defaultCenter = {
      lat: leading?.coords?.lat,
      lng: leading?.coords?.lng,
    }
  }

  if (type === "city") {
    defaultZoom = env.maps.defaultZoom.city
  }
  if (type === "county") {
    defaultZoom = env.maps.defaultZoom.county
  }

  const [stations, setStations] = useState([])
  const [initialized, setInitialized] = useState(false)
  const [center, setCenter] = useState(defaultCenter)
  const [zoom, setZoom] = useState(defaultZoom)

  const selfMarkerRef = useRef(null)
  const [googleRef, setGoogleRef] = useState(null)
  const [googleMapRef, setGoogleMapRef] = useState(null)
  const clustererRef = useRef(null)

  const { get, response, loading, error } = useFetch(env.api.prices)

  useEffect(() => {
    typeof window?.gtag !== "undefined" &&
      window.gtag("event", "map_telemetry", {
        loaded: true,
      })
  }, [])

  //geolocation changed
  useEffect(() => {
    if (
      !geolocation.latitude ||
      !geolocation.longitude ||
      !googleRef ||
      !googleMapRef
    ) {
      return
    }

    if (!initialized) {
      paintMarkers()
      setInitialized(initialized)

      typeof window?.gtag !== "undefined" &&
        window.gtag("event", "map_telemetry", {
          geolocation: true,
        })

      if (type === "index") {
        setZoom(env.maps.defaultZoom.station)
        setCenter({
          lat: geolocation.latitude,
          lng: geolocation.longitude,
        })
      }
    }

    if (selfMarkerRef.current) {
      selfMarkerRef.current.setPosition({
        lat: geolocation.latitude,
        lng: geolocation.longitude,
      })
    }
  }, [geolocation, featured, initialized])

  async function loadAllStations() {
    const fetchedStations = await get(`/list/identifier,coords,url/gzip`)

    if (response.ok && fetchedStations) {
      setStations(fetchedStations)
    }
  }

  useEffect(() => {
    if (!googleRef || !googleMapRef) {
      return
    }
    loadAllStations()
  }, [googleRef, googleMapRef])

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "/js/markerclusterer.js"
    script.async = true
    document.body.appendChild(script)
  }, [])

  // https://github.com/google-map-react/google-map-react/issues/473
  function paintMarkers() {
    const markers = stations
      .filter(
        station =>
          station.coords.lat !== featured?.coords?.lat &&
          station.coords.lng !== featured?.coords?.lng &&
          station.coords.lat !== leading?.coords?.lat &&
          station.coords.lng !== leading?.coords?.lng
      )
      .map(station => {
        const marker = new googleRef.Marker({
          position: station.coords,
          icon: markerIconInactive,
        })
        marker.addListener("click", () => {
          window.location.href = `/${station.url}`
        })
        return marker
      })
    if (geolocation.latitude && geolocation.longitude) {
      const self = new googleRef.Marker({
        position: { lat: geolocation.latitude, lng: geolocation.longitude },
        icon: markerIconSelfActive,
      })
      markers.push(self)
      selfMarkerRef.current = self
    }
    if (featured) {
      const featuredMarker = new googleRef.Marker({
        position: featured.coords,
        icon: markerIconActive,
      })
      markers.push(featuredMarker)
    }
    if (leading) {
      const leadingMarker = new googleRef.Marker({
        position: leading.coords,
        icon: markerIconActive,
      })
      leadingMarker.addListener("click", () => {
        window.location.href = `/${leading.url}`
      })
      markers.push(leadingMarker)
    }

    if (clustererRef.current) {
      clustererRef.current.clearMarkers()
    }
    clustererRef.current = new MarkerClusterer(googleMapRef, markers, {
      imagePath: `//${window.location.host}/marker/m`,
      gridSize: env.maps.cluster.gridSize,
      minimumClusterSize: env.maps.cluster.minimumClusterSize,
    })
  }

  useEffect(() => {
    if (stations.length < 1) {
      return
    }

    paintMarkers()
  }, [stations])

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: env.maps.key,
        region: "de",
        language: "de",
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps: google }) => {
        setGoogleMapRef(map)
        setGoogleRef(google)
      }}
      onDragEnd={map => {
        setCenter({ lat: map.center.lat(), lng: map.center.lng() })
      }}
      onChange={event => {
        setCenter(event.center)
        setZoom(event.zoom)
      }}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      zoom={zoom}
      center={center}
      options={{
        clickableIcons: false,
        styles,
      }}
    ></GoogleMapReact>
  )
}

export default LocationMap
