import styled from "styled-components"

export const AdsenseAd = styled.div`
  margin: 2rem 1rem;
  max-width: 1200px;
  min-width: 250px;
  position: relative;
  ${props => props.theme.breakpoints.up("md")} {
    margin: 2rem auto;
  }
`

export const AdsenseAdMobile = styled(AdsenseAd)`
  display: block;
  ${props => props.theme.breakpoints.up("md")} {
    display: none;
  }
`
export const AdsenseAdLaptop = styled(AdsenseAd)`
  display: none;
  ${props => props.theme.breakpoints.up("md")} {
    display: block;
  }
`
