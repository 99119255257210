// http://www.movable-type.co.uk/scripts/latlong.html
export default function distance(start, end) {
  const decimals = 2
  const earthRadius = 6371 // km
  //   let lat1 = parseFloat(start.lat)
  //   let lat2 = parseFloat(end.lat)
  //   let lon1 = parseFloat(start.long)
  //   let lon2 = parseFloat(end.long)

  const dLat = ((end.lat - start.lat) * Math.PI) / 180
  const dLon = ((end.lng - start.lng) * Math.PI) / 180
  const lat1 = (start.lat * Math.PI) / 180
  const lat2 = (end.lat * Math.PI) / 180

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = earthRadius * c
  return Math.round(d * Math.pow(10, decimals)) / Math.pow(10, decimals)
}
