import React from "react"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import CardActionArea from "@material-ui/core/CardActionArea"
import Typography from "@material-ui/core/Typography"

import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

const StyledGridContainer = styled(Grid)`
  width: calc(100% - 2rem);
  margin: 1rem;
  ${props => props.theme.breakpoints.up("md")} {
    width: 100%;
    margin: 0 0 1rem 0;
  }
`

const StyledGridItem = styled(Grid)``
const StyledCard = styled(Card)`
  border: 1px solid ${props => props.theme.palette.secondary.dark};
  color: ${props => props.theme.palette.secondary.contrastText};
  background: ${props => props.theme.palette.secondary.main};
  &.active-county,
  &:hover,
  &:active,
  &:focus {
    background: ${props => props.theme.palette.primary.main};
  }
`

const StyledBox = styled(Box)`
  padding: 0.5rem;
  min-height: 4rem;
  display: flex;
`

const StyledGridFlex = styled(Grid)`
  flex: 1;
`

const StyledGridCentered = styled(Grid)`
  text-align: center;
`

const StyledImage = styled.img`
  width: 50%;
  height: 50%;
`

export default function Counties(props) {
  const data = useStaticQuery(
    graphql`
      query CountyFilesQuery {
        allFile(
          filter: {
            relativePath: {}
            root: {}
            relativeDirectory: { eq: "counties" }
          }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `
  )

  const countyFiles = data.allFile.edges.map(e => e.node)

  const { current, counties } = props

  return (
    <StyledGridContainer container spacing={0}>
      {counties.map(entry => (
        <StyledGridItem item xs={12} sm={6} md={3} key={entry.county}>
          <StyledCard
            className={current === entry.county ? "active-county" : null}
          >
            <CardActionArea
              href={`/${entry.countycleaned.toLowerCase()}`}
              title={`Tankstellen in ${entry.county}`}
            >
              <StyledBox>
                <StyledGridFlex container spacing={0} alignItems="center">
                  <StyledGridCentered item xs={3}>
                    <StyledImage
                      alt={entry.county}
                      title={entry.county}
                      src={
                        countyFiles.filter(
                          e => e.name === entry.countycleaned
                        )[0].publicURL
                      }
                    />
                  </StyledGridCentered>
                  <Grid item xs={9}>
                    <Typography variant="body2">
                      {entry.county} ({entry.count})
                    </Typography>
                  </Grid>
                </StyledGridFlex>
              </StyledBox>
            </CardActionArea>
          </StyledCard>
        </StyledGridItem>
      ))}
    </StyledGridContainer>
  )
}
